<template>
    <div class="warp" v-loading="Loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="main">
            <div v-if="info.type == 1 || info.type == 2">
                <div style="text-align:center;margin-top:40px;">
                    <div>
                        <p>温馨提示</p>
                    </div>
                    <div style="text-align:left;padding:0px 142px;">
                        <p>1、CDK回收是指可以利用多余或垃圾CDK(不含已使用),通过本活动进行回收</p>
                        <p>2、每一张CDK回收成功都可以获得[{{ info.type_name }}]。回收成功请刷新商城即可。</p>
                        <p style="text-align:center;margin-top:8px;">当前比例：1:{{ info.type_name }}
                            也就说1张CDK获得[{{ info.type_name }}]</p>
                    </div>
                </div>
                <div style="padding:2px 140px;">
                    <p style="margin-bottom:10px;">
                        <span>游戏账号：</span><input type="text" v-model="form.username"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>游戏密码：</span><input type="password" v-model="form.password"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p>
                        <span>卡密内容：</span>
                        <textarea v-model="form.cdk" wrap="off" placeholder="E7664A21E85FF0811386C68B60"
                            style="height:138px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;"></textarea>
                    </p>
                    <div style="text-align:center;">
                        <button style="padding:4px 30px;background:#49392d;border:1px solid #846f5c;color:#c5b48e;"
                            @click="search">提交回收</button>
                    </div>
                </div>
            </div>
            <div v-if="info.type == 3">
                <div style="text-align:center;margin-top:40px;">
                    <div>
                        <p>温馨提示</p>
                    </div>
                    <div style="text-align:left;padding:0px 142px;">
                        <p>1、CDK回收是指可以利用您的多余或垃圾CDK(不含已使用)的通过活动进行回收</p>
                        <p>2、回收的内容以游戏币模式回收。</p>
                        <p style="text-align:center;margin-top:8px;">1:100
                            也就说1张CDK获得{{ info.type_name }}。回收成功以邮箱方式发送，请小退领取即可。</p>
                    </div>
                </div>
                <div style="padding:2px 140px;">
                    <p style="margin-bottom:10px;">
                        <span>游戏账号：</span><input type="text" v-model="form.username"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>游戏密码：</span><input type="password" v-model="form.password"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>角色获取：</span>
                        <select v-model="form.role"
                            style="height:20px;width:30%;background:#2a1d15;color:#c5b48e;padding:2px 0px;border:1px solid #3e3e3e;">
                            <option value="">请选择</option>
                            <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                {{ itmec.charac_name }}</option>
                        </select>
                        <button style="background:#2a1d15;color:#c5b48e;padding:0px 6px;border:0px solid #8c7d74;"
                            @click="getRole">获取</button>
                    </p>
                    <p>
                        <span>卡密内容：</span>
                        <textarea v-model="form.cdk" wrap="off" placeholder="E7664A21E85FF0811386C68B60"
                            style="height:88px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;"></textarea>
                    </p>
                    <div style="text-align:center;">
                        <button style="padding:4px 30px;background:#49392d;border:1px solid #846f5c;color:#c5b48e;"
                            @click="search">提交回收</button>
                    </div>
                </div>
            </div>
            <div v-if="info.type == 4">
                <div style="text-align:center;margin-top:40px;">
                    <div>
                        <p>温馨提示</p>
                    </div>
                    <div style="text-align:left;padding:0px 142px;">
                        <p>1、CDK回收是指可以利用您的多余或垃圾CDK(不含已使用)的通过活动进行回收</p>
                        <p>2、回收的内容以物品模式回收。回收成功以邮箱方式发送，请小退领取即可。</p>
                    </div>
                </div>
                <div style="padding:14px 140px;">
                    <p style="margin-bottom:10px;">
                        <span>奖励选择：</span>
                        <select v-model="form.itemsid"
                            style="height:20px;width:60%;background:#2a1d15;color:#c5b48e;padding:2px 0px;border:1px solid #3e3e3e;">
                            <option value="">选择兑换奖励</option>
                            <option v-for="(itmec, i) in info.type_array" :key="i" :value="itmec.id">{{ itmec.name }}
                            </option>
                        </select>
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>游戏账号：</span><input type="text" v-model="form.username"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>游戏密码：</span><input type="password" v-model="form.password"
                            style="height:20px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;">
                    </p>
                    <p style="margin-bottom:10px;">
                        <span>角色获取：</span>
                        <select v-model="form.role"
                            style="height:20px;width:30%;background:#2a1d15;color:#c5b48e;padding:2px 0px;border:1px solid #3e3e3e;">
                            <option value="">请选择</option>
                            <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                {{ itmec.charac_name }}</option>
                        </select>
                        <button style="background:#2a1d15;color:#c5b48e;padding:0px 6px;border:0px solid #8c7d74;"
                            @click="getRole">获取</button>
                    </p>
                    <p>
                        <span>卡密内容：</span>
                        <textarea v-model="form.cdk" wrap="off" placeholder="E7664A21E85FF0811386C68B60"
                            style="height:88px;width:100%;background:#2a1d15;border:1px solid #3e3e3e;padding:2px 2px;color:#c5b48e;"></textarea>
                    </p>
                    <div style="text-align:center;">
                        <button style="padding:10px 30px;background:#49392d;border:1px solid #846f5c;color:#c5b48e;"
                            @click="search">提交回收</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert">
            <!-- 弹出框 -->
            <div class="alert_bg" :style="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div class="btn" @click="CloseAlert">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框end -->


            <!-- 回收显示框 -->
            <div class="alert_bg" v-if="recyresults">
                <div class="alert_main" style="margin:120px auto 0">
                    <div class="alert_title"><span>回收结果</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <textarea style="width:94%;height:130px" v-model="recycdk"></textarea>
                            <div class="btn" @click="recyresults = false">关闭</div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 弹出框end -->
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
export default {
    name: "Cloud",
    data () {
        return {
            MessageDisplay: 'display:none', //弹出框显示
            LoginDisplay: 'display:none', //登陆弹出框
            LoginError: '',
            MessageMsg: '', //弹出框消息
            Loading: false,
            recyresults: false, //回收结果弹出框
            recycdk: '', //回收结果
            info: {
                type: 1,
                type_name: '',
                type_array: [],
            },
            form: {
                username: '',
                password: '',
                cdk: '',
                role: '',
                itemsid: '',
            },
            role_data: [],
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        //获取基本配置信息
        getInfo () {
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.webplugurl + '/recy/getRecyInfo',
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.info = response.data.info
                } else {
                    this.PopMessage(true, response.data.msg)
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //提交
        search () {
            if (this.Loading == true) {
                return false
            }
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.webplugurl + '/recy/getRecyRecycling',
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.form.username,
                    password: this.form.password,
                    cdk: this.form.cdk,
                    role: this.form.role,
                    itemsid: this.form.itemsid,
                },
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.recyresults = true
                    this.recycdk = response.data.cdk
                } else {
                    this.PopMessage(true, response.data.msg)
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false
            }
            this.Loading = true
            this.axios({
                // 默认请求方式为get
                method: 'post',
                url: Vue.prototype.config.webplugurl + '/getRole',
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.form.username,
                    password: this.form.password,
                },
                responseType: 'json'
            }).then(response => {
                this.Loading = false
                if (response.data.code == 200) {
                    this.role_data = response.data.info
                    this.PopMessage(true, response.data.msg)
                } else {
                    this.PopMessage(true, response.data.msg)
                }
            }).catch(error => {
                this.Loading = false
                // 请求失败，
                console.log(error)
            });
        },
        //弹出框状态
        PopMessage (type, msg) {
            this.MessageDisplay = "display:none"
            this.MessageMsg = msg
            if (type == true) {
                this.MessageDisplay = "display:block"
            } else {
                this.getInfo()
            }
        },
        sequenceDownload (row) {
            const element = document.createElement('a')
            element.setAttribute(
                'href',
                'data:text/plain;charset=utf-8,' + encodeURIComponent(row)
            )
            element.setAttribute('download', '回收结果')
            element.style.display = 'none'
            element.click()
        },
        //关闭窗口
        CloseAlert () {
            this.PopMessage(false, '')
        },
    },
};
</script>

<style scoped>
@import "../../../public/static/plug/recy/static/css/game.css";
</style>
<style>
#root,
body,
html {
    min-width: 0px;
}
</style>